<template>
  <div class="custom-modal" v-if="visible">
    <div class="modal-overlay"></div>
    <div class="modal-content"  style="background:#fafafa;">
      <!-- position:relative; top:29px;    z-index: 11111; -->
      <div style="">
      <div class="px-3 py-2 d-flex justify-content-between align-items-center" style="background:#fff; box-shadow:0px 0px 2px #dedede">
<div style="cursor:pointer" @click="$emit('close')"> <i
                class="fa fa-arrow-left mr-3 headTitle"
                style="font-size: 16px"
                aria-hidden="true"
              ></i>Back</div>
              <div class="h4 m-0 p-0">{{ title }}</div>
              <slot name="button"></slot>
 
</div>

        </div>
      <div class="modal-body">

   <div class="d-flex w-100 justify-content-center" >
   
   
<slot ></slot>


</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return{
      isDesktopValue:true
    }
  },
  props: {
    title: {
      type: String,
      default: "Custom Full-Screen Modal",
    },
   
    visible: {
      type: Boolean,
      default: false,
    },
   
  },
   components:{

    },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    
  },
};
</script>

<style>
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index:1001;
  /* display: flex */
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.modal-content {
  
    /* padding: 20px; */
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    min-width: 100%;
    min-height: 100%;
    overflow: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
}

.modal-header button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.toggleButton {
    /* background: #f6f6f6; */
    color: #292929;
    font-size: 14px;
    font-weight: 400px;
}
.toggleSelectedButton {
    background: rgba(77, 27, 126, 0.8) !important;
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 500px !important;
}
.toggleSelectedButton:hover {
    color: #ffffff !important;
}
.toggleBtn{
  min-width: 119px;
    font-size: 14px;
    Background: #fff;
    border-radius: 6px;
    min-width: 119px;
    font-size: 14px;
    border:none;
}
</style>
